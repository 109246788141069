import { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";

// Components Import
import Navbar from "./components/Navbar";
import MyFatoorahGateway from "./PaymentProviders/MyFatoorah/MyFatoorah";
import PayfortGateway from "./PaymentProviders/PayFort/PayFort";

import { baseUrl } from "../constants/api";
import axios from "axios";

// payment-gateway

function Payment() {
	const token = window.localStorage.getItem("mp-user-token");

	const [paymentGateway, setPaymentGateway] = useState(null);
	const [loading, setLoading] = useState(true);

	const getPaymentGateway = async () => {
		axios
			.get(`${baseUrl}/api/payment-gateway`, {
				headers: {
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setPaymentGateway(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getPaymentGateway()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Navbar />
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80vh",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<>
					{paymentGateway?.payment_provider === 2 ? (
						<MyFatoorahGateway />
					) : paymentGateway?.payment_provider === 1 ? (
						<PayfortGateway />
					) : null}
				</>
			)}
		</>
	);
}

export default Payment;
