import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from "axios";
import CustomChip from "../../../../components/CustomChip";

// MUI Components Import
import {
	Box,
	Grid,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Pagination,
} from "@mui/material";

// Loader Import
import Loader from "../../Loader";

// React Toastify Imports
import { useTranslation } from "../../../../contexts/LanguageContext";
import { baseUrl } from "../../../../constants/api";

function RefundHistory() {

	const { translate, getDirection } = useTranslation();

	const token = window.localStorage.getItem("mp-user-token");

	const [page, setPage] = useState(1);
	const rowsPerPage = 5;

	const [refunds, setRefunds] = useState([]);
	const [ordersCount, setOrdersCount] = useState("");
	const [loading, isLoading] = useState(false);

	const fetchRefundHistory = () => {
		isLoading(true);
		axios
			.get(`${baseUrl}/api/refunds/customer?limit=${rowsPerPage}&offset=${page}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setRefunds(response.data.results);
				setOrdersCount(response.data.total_count);
				isLoading(false);
			})
			.catch((err) => {
				console.log("Error", err);
				isLoading(false);
			});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	useEffect(() => {
		fetchRefundHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<Wrapper dir={getDirection()}>
			<Grid container>
				<CardHeading>{translate("refund-history.history")}</CardHeading>
				<Card item md={12}>
					<TableWrapper component={Paper}>
						<Table>
							<TableHead style={{ backgroundColor: "#DDDDDD" }}>
								<TableRow>
									<TableHeadings>#</TableHeadings>
									<TableHeadings>
										{translate("refund-history.id")}
									</TableHeadings>
									<TableHeadings>
										{translate("refund-history.at")}
									</TableHeadings>
									<TableHeadings>
										{translate("refund-history.status")}
									</TableHeadings>
									<TableHeadings>
										Order Id
									</TableHeadings>
									<TableHeadings>
										{translate("refund-history.refund")}
									</TableHeadings>
									<TableHeadings>Remarks</TableHeadings>
								</TableRow>
							</TableHead>
							<TableBody>
								{loading ? (
									<Loader />
								) : refunds.length === 0 ? (
									<TableRow align="center">
										<TableContent colSpan={7} align="center">
											{translate("refund-history.not")}
										</TableContent>
									</TableRow>
								) : (
									refunds?.map((row, index) => (
										<TableRow key={index}>
											<TableContent>{index + 1}</TableContent>
											<TableContent sx={{ fontWeight: "700" }}>
												{row?.rfOId}
											</TableContent>
											<TableContent>
												{new Date(row?.created_at).toLocaleString()}
											</TableContent>
											<TableContent>
												<Box>
													{<CustomChip label={row?.refund_status_dsiplay} />}
												</Box>
											</TableContent>
											<TableContent sx={{ fontWeight: "700" }}>
												{row?.order?.ordId}
											</TableContent>
											<TableContent>
												{parseFloat(row?.refund_amount || 0).toLocaleString(
													undefined,
													{
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}
												)}{" "}
												SAR
											</TableContent>
											<TableContent sx={{ fontWeight: "700" }}>
												{row?.refund_reason}
											</TableContent>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableWrapper>
					<Box
						sx={{
							padding: "10px",
							background: "#fff",
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Pagination
							count={Math.ceil(ordersCount / rowsPerPage)}
							shape="rounded"
							onChange={handleChangePage}
						/>
					</Box>
				</Card>
			</Grid>
		</Wrapper>
	)
}

const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	margin: "10px",
	[theme.breakpoints.down("sm")]: {
		width: "auto",
	},
}));

const Card = styled(Grid)(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	background: "#fff",
	border: "1px solid #DDDDDD",
}));

const CardHeading = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "600",
	padding: "12px 2px",
}));

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none",
}));

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "black",
	background: "#F2F4F5",
	textAlign: "center",
}));

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D",
	border: "none",
	textAlign: "center",
}));

export default RefundHistory